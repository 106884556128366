<template>
  <div>
    <base-page-heading title="Editare utilizator" v-bind:subtitle="user ? user.firstName+' '+user.lastName : ''">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Utilizatori</b-breadcrumb-item>
          <b-breadcrumb-item active>Editare utilizator <span v-if="user">{{user.firstName}} {{user.lastName}}</span></b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <b-col xl="12">
          <base-block>
            <b-row class="row-deck">
              <b-col md="4" class="col-12">
                <b-button size="md" type="submit" variant="primary" class="mb-3" @click="backToList">
                  <small><i class="fa fa-fw fa-arrow-left"></i> Inapoi la lista de utilizatori</small>
                </b-button>
              </b-col>
            </b-row>
            <hr/>
            <b-form @submit.stop.prevent="onAddUserSubmit">
              <b-row>
                <b-col xl="6">
                  <base-block title="Introduce date personale" header-bg content-full>
                    <b-row>
                      <b-col xl="8" offset-lg="2">
                        <base-block header-bg>
                          <div class="font-size-sm push">
                            <div class="form-group">
                              <div class="form-group">
                                <b-form-input size="lg" class="form-control-alt" id="firstName" name="firstName" placeholder="Prenume" v-model="$v.editUserForm.firstName.$model" :state="!$v.editUserForm.firstName.$error && (!errors.editUserForm.firstName) && null" aria-describedby="firstName-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-input>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.firstName.required" id="firstName-feedback">
                                  Campul este obligatoriu!
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.firstName.minLength" id="firstName-feedback">
                                  Campul trebuie sa aiba minimum 3 caractere!
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="errors.editUserForm.firstName" v-text="errors.editUserForm.firstName" id="firstName-feedback"></b-form-invalid-feedback>
                              </div>
                              <div class="form-group">
                                <b-form-input size="lg" class="form-control-alt" id="lastName" name="lastName" placeholder="Nume" v-model="$v.editUserForm.lastName.$model" :state="!$v.editUserForm.lastName.$error && (!errors.editUserForm.lastName) && null" aria-describedby="lastName-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-input>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.lastName.required" id="firstName-feedback">
                                  Campul este obligatoriu!
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.lastName.minLength" id="firstName-feedback">
                                  Campul trebuie sa aiba minimum 3 caractere!
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="errors.editUserForm.lastName" v-text="errors.editUserForm.lastName" id="firstName-feedback"></b-form-invalid-feedback>
                              </div>
                              <div class="form-group">
                                <b-form-input size="lg" class="form-control-alt" id="email" name="email" placeholder="E-mail" v-model="$v.editUserForm.email.$model" :state="!$v.editUserForm.email.$error && (!errors.editUserForm.email) && null" aria-describedby="email-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-input>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.email.required" id="firstName-feedback">
                                  Campul este obligatoriu!
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.email.email" id="email-feedback">
                                  Adresa de email este invalida!
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="errors.editUserForm.email" v-text="errors.editUserForm.email" id="firstName-feedback"></b-form-invalid-feedback>
                              </div>
                              <b-form-group label-for="headquarter">
                                <b-form-select id="headquarter" class="form-control-alt" :options="headquartersChoices" plain name="headquarter" v-model="$v.editUserForm.headquarter.$model" :state="!$v.editUserForm.headquarter.$error && (!errors.editUserForm.headquarter) && null" aria-describedby="headquarter-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.editUserForm.headquarter.required" id="headquarter-feedback">
                                  Campul este obligatoriu!
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </div>
                          </div>
                        </base-block>
                      </b-col>
                    </b-row>
                  </base-block>
                </b-col>
                <b-col xl="6">
                  <base-block title="Setare parola si acces la module" header-bg content-full>
                    <b-row>
                      <b-col xl="8" offset-lg="2">
                        <base-block header-bg>
                          <div class="font-size-sm push">
                            <div class="form-group">
                              <b-form-input type="password" size="lg" class="form-control-alt" id="plainPassword" name="plainPassword" placeholder="Parola" v-model="$v.editUserForm.plainPassword.$model" :state="!$v.editUserForm.plainPassword.$error && (!errors.editUserForm.plainPassword) && null" aria-describedby="newPassword-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-input>
                              <b-form-invalid-feedback v-if="!$v.editUserForm.plainPassword.minLength" id="newPassword-feedback">
                                Campul trebuie sa aiba minimum 6 caractere!
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-if="errors.editUserForm.plainPassword" v-text="errors.editUserForm.plainPassword" id="newPassword-feedback"></b-form-invalid-feedback>
                            </div>
                            <div class="form-group">
                              <b-form-input type="password" size="lg" class="form-control-alt" id="passwordConfirm" name="passwordConfirm" placeholder="Repeta parola" v-model="$v.editUserForm.passwordConfirm.$model" :state="!$v.editUserForm.passwordConfirm.$error && null" aria-describedby="passwordConfirm-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-input>
                              <b-form-invalid-feedback v-if="!$v.editUserForm.passwordConfirm.sameAsPassword" id="passwordConfirm-feedback">
                                Parola nu se potriveste!
                              </b-form-invalid-feedback>
                            </div>
                          </div>
                        </base-block>
                        <b-form-group label="Permite acces la urmatoarele module" label-class="font-md">
                          <b-form-checkbox-group :options="enabledModulesChoices" stacked name="enabledModules" v-model="$v.editUserForm.enabledModules.$model" :state="!$v.editUserForm.enabledModules.$error && (!errors.editUserForm.enabledModules) && null" aria-describedby="enabledModules-feedback" @blur="removeServerErrors('editUserForm')" @input="removeServerErrors('editUserForm')"></b-form-checkbox-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </base-block>
                </b-col>
              </b-row>
              <b-row>
                <b-col offset-md="4" md="2">
                  <base-block header-bg>
                    <b-button type="submit" variant="primary" block>
                      <i class="fa fa-fw fa-pencil-alt mr-1"></i> Editare
                    </b-button>
                  </base-block>
                </b-col>
                <b-col md="2">
                  <base-block header-bg>
                    <b-button v-if="user.active" type="button" variant="danger" block @click="onStatusChange">
                      <i class="fa fa-fw fa-window-close mr-1"></i> Suspenda
                    </b-button>
                    <b-button v-if="!user.active" type="button" variant="success" block @click="onStatusChange">
                      <i class="fa fa-fw fa-check mr-1"></i> Activeaza
                    </b-button>
                  </base-block>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {
  CHANGE_USER_STATUS,
  EDIT_USER_MUTATION,
  USER_COMPANY_DETAILS_FOR_EDIT
} from "../../../constants/user-graphql";
import { handleFormValidation, fleshErrorMessage } from "@/error/server-error";

export default {
  name: "EditUser",
  mixins: [validationMixin],
  data () {
    return {
      userId: atob(this.$route.params.id),
      user: null,
      enabledModulesChoices: {},
      headquartersChoices: {},
      editUserForm: {
        firstName: null,
        lastName: null,
        email: null,
        plainPassword: null,
        passwordConfirm: null,
        enabledModules: null,
        headquarter: null,
      },
      errors: {
        editUserForm: {},
      },
    }
  },
  validations: {
    editUserForm: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      plainPassword: {
        minLength: minLength(6)
      },
      passwordConfirm: {
        sameAsPassword: sameAs('plainPassword')
      },
      headquarter: {
        required
      },
      enabledModules: {},
    }
  },
  methods: {
    onAddUserSubmit () {
      this.errors.editUserForm = {};
      this.$v.editUserForm.$touch()
      if (this.$v.editUserForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: EDIT_USER_MUTATION,
        variables: {
          'userId': this.user._id,
          'firstName': this.$v.editUserForm.firstName.$model,
          'lastName': this.$v.editUserForm.lastName.$model,
          'email': this.$v.editUserForm.email.$model,
          'headquarterID': this.$v.editUserForm.headquarter.$model,
          'password': this.$v.editUserForm.plainPassword.$model,
          'enabledModules': this.$v.editUserForm.enabledModules.$model,
        }
      }).then(() => {
        this.flashMessage.info({message: "Utilizatorul este editat cu succes!"});
        this.$router
            .push('/user/list')
            .catch(error => {
              error = {};
              fleshErrorMessage(error, this);
            });
      }).catch((error) => {
        let form = this.$v.editUserForm;
        this.errors.editUserForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    onStatusChange () {
      this.$swal({
        icon: 'warning',
        title: this.user.active ? 'Suspendare' : 'Activare',
        html: 'Esti sigur ca vrei sa '+(this.user.active ? 'suspenzi' : 'activezi')+' utilizatorul <strong>'+this.user.name+'</strong>',
        showCancelButton: true,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary m-1',
          cancelButton: 'btn btn-secondary m-1'
        },
        confirmButtonText: 'Da!',
        cancelButtonText: 'Nu',
      }).then(result => {
        if (result.value) {
          this.$apollo.mutate({
            mutation: CHANGE_USER_STATUS,
            variables: {
              'userId': this.user._id
            }
          }).then(() => {
            this.flashMessage.info({message: "Utilizatorul este "+(this.user.active ? 'suspendat' : 'activat')+" cu succes!"});
            this.$router
                .push('/user/list')
                .catch(error => {
                  error = {};
                  fleshErrorMessage(error, this);
                });
          }).catch((error) => {
            let form = this.$v.editUserForm;
            this.errors.editUserForm = handleFormValidation(error, form);
            fleshErrorMessage(error, this);
          })
        }
      })
    },
    backToList() {
      this.hasHistory() ?
          this.$router.go(-1) :
          this.$router.push('/user/list')
      ;
    },
    hasHistory() {
      return window.history.length > 2
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    }
  },
  apollo: {
    meUser: {
      query: USER_COMPANY_DETAILS_FOR_EDIT,
      fetchPolicy: "no-cache",
      variables() {
        return {
          userId: this.userId,
        }
      },
      result(result) {
        this.enabledModulesChoices = result.data.meUser.company.enabledModuleChoices;
        let choices = [];
        choices.push({'value': null, 'text': 'Selecteaza punctul de lucru'});
        result.data.meUser.company.headquarters.forEach(function(item) {
          choices.push({'value': item._id, 'text': item.name});
        });
        this.user = result.data.meUser.company.user[0];
        this.headquartersChoices = choices;
        this.editUserForm.firstName = this.user.firstName;
        this.editUserForm.lastName = this.user.lastName;
        this.editUserForm.email = this.user.email;
        this.editUserForm.headquarter = this.user.companyHeadquarter._id;
        this.editUserForm.enabledModules = this.user.userRole.enabledModuleIdentifierList;
        this.loaded = true;
      }
    }
  },
}
</script>